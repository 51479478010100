@media only screen and (max-width: 640px) {
  #app-content > div {
    overflow-y: inherit;
  }
  .second-line-header {
    height: auto;
  }
  #top-chart {
    margin-top: 25px;
  }

  .report-item.insight {
    display: none;
  }
  .cgs-chart-menu {
    top: 0;
    right: 0;
    padding: 0 15px;
    z-index: 1;
  }
  h1.operation-name {
    margin-top: 15px;
  }
}