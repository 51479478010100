html {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;

}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, h1, h2, h3, h4, h5, h6 {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-weight: 100;

}

.uk-text-lead {
  font-weight: 300;
}

.uk-logo {
  font-weight: 300;

}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background-color: #333;
}

.uk-navbar-item, .uk-navbar-nav > li > a, .uk-navbar-toggle {
  height: 45px;

}

.uk-nav-primary .uk-nav-sub a {
  color: #666;

}

.uk-nav-sub li {
  font-size: 18px !important;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  margin-right: -15px;

}

.uk-nav-sub li:hover {
  background-color: #fafafa;

}

.uk-search-default .uk-search-input {
  background-color: #fafafa;

}

.uk-light .uk-select  option {
  color: #333;
}

.uk-search-default .uk-search-input:focus {
  background-color: #ffffff;

}