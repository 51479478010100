// Variables
@import "variables";
@import "uikit-custom";

html, body {
  height: 100%;
  background-color: #eee;
}

.cgs-clickable {
  cursor: pointer;
}

#app-content {
  height: calc(100% - 45px);
}

#app-content > div {
  height: 100%;
}

#left-panel {
  height: auto !important;
  background-color: #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#left-panel > .wrapper {

  padding: 0 15px;
}

#left-panel .material-icons {
  margin-top: -5px;
  vertical-align: middle;
}

#left-panel li.active {
  background-color: #fafafa;
}

#content-panel {
  overflow-y: auto;
  background-color: #fff;
}

.second-line-header {
  height: 300px;
  position: relative;

  background: #fafafa; /* Old browsers */
  background: -moz-radial-gradient(top, ellipse cover, #fafafa 0%, #eeeeee 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(top, ellipse cover, #fafafa 0%, #eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at top, #fafafa 0%, #eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fafafa', endColorstr='#eeeeee', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

#analysis-viewport {
  background-color: #fff;
}

.cgs-chart-menu {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.cgs-slider {
  height: 100%;
}

.cgs-slide {
  height: 100%;
  text-align: center;
  background: no-repeat center center;
  background-size: cover;
}

.cgs-slide h1,
.cgs-slide h2 {
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.cgs-slide h1 {
  font-size: 2rem;
}

.cgs-slide h2 {
  font-size: 1.5rem;
}

.slide-left {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -50px;
  cursor: pointer;
}

.slide-right {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -50px;
  cursor: pointer;
}

.cgs-text-thin {
  font-weight: 100;
}

.cgs-text-light {
  font-weight: 300;
}

.cgs-content-padding {
  padding: 15px;
}

.cgs-content-padding-large {
  padding: 30px;
}

.cgs-loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: none;
}

.cgs-data-table tr {

  td:not(:first-child),
  th:not(:first-child) {
    text-align: right;
  }

}

@media all {
  .cgs-page-break {
    display: none;
  }
}

@media print {

  .cgs-page-break {
    display: block;
    page-break-before: always;
  }

  .cgs-no-print, .cgs-no-print * {
    display: none !important;
  }
  .cgs-chart-menu {
    display: none !important;
  }

  #app-content > div {
    overflow-y: inherit;
  }
  .second-line-header {
    height: auto;
  }
  #top-chart {
    margin-top: 25px;
  }

  .report-item {
    page-break-inside: avoid;
  }
}

@import "mobile";
